/* global $ */
import { _ } from "$json";
import { quotable } from "$json/lib/quotable.js";
import { format } from "$json/lib/text-utils.js";
/* show-server-side-validation:
 *
 * Show errors after server-side validation.
 *
 * Copyright (C) 2011, 2017 by Berkeley Bridge
 *
 */
const options = {
  silent: false,
  justhide: false
};

$(document).on("bb:postHandleData", function (event, data) {
  if (data && data.lasterrors && data.lasterrors.length > 0) {
    var error;
    for (var i = 0, len = data.lasterrors.length; i < len; i++) {
      error = data.lasterrors[i];
      const control = data.groups
        .find(({ current }) => current)
        .controls.find(({ id }) => id === `${data.screenid}-${error.name}`);

      $(`[id="${data.screenid}-${error.name}"]`).showValidation(
        options,
        false,
        format(map(error.errortext), quotable(control))
      );
    }
  }
});

function map(errortext) {
  switch (errortext) {
    case "Input mandatory":
      return _("Input required");
    case "E4004:Choice is mandatory":
      return _("Choice required");
    default:
      return _(errortext);
  }
}
