/** Map fn on array, then join it with joiner */
import * as Lenses from "./lenses.js";

export const applyTo = arg => fn => fn(arg);

const _apply = (fn, args) => fn(...args);

export const dec = arg => arg - 1;

export const inc = arg => arg + 1;

export function mapConcat(arr, fn, joiner) {
  return arr.map(fn).join(joiner);
}

export const identity = x => x;

export const isNil = x => x == null;

export const not = a => !a;

/**
 Differs from Ramda in that the first function in the pipe chain is
 called with a single argument.
 */
export const pipe =
  (...fns) =>
  value =>
    fns.reduce((acc, cur) => cur(acc), value);

/**
 Differs from Ramda in that the first function in the compose chain is
 called with a single argument.
 */
export const compose =
  (...fns) =>
  value =>
    fns.reduceRight((acc, cur) => cur(acc), value);

/**
 Same as Ramda compose() in that the first function in the compose
 chain may be called with more than one argument.
 */
export const composeX =
  (...fns) =>
  (...value) =>
    fns.reduceRight((acc, cur) => [cur.apply(null, acc)], value);

export const curry = (fn, ...args) => {
  if (args.length >= fn.length) return fn(...args);
  return (...args2) => curry(fn, ...[...args, ...args2]);
};

export const join = curry((sep, arr) => arr.join(sep));

export const split = curry((sep, arr) => arr.split(sep));

export const reverse = arr => [...arr].reverse();

export const type = compose(
  s => s.slice(8, -1),
  ob => Object.prototype.toString.call(ob)
);

export const always = value => () => value;

export const F = always(false);

export const T = always(true);

export const propOr = curry((or, prop, obj) => {
  try {
    return ifElse(isNil, () => or, identity)(obj[prop]);
  } catch (_) {
    return or;
  }
});

export const prop = propOr(undefined);
export const pathOr = curry((or, pathArg, obj) =>
  reduce((acc, cur) => propOr(or, cur, acc), obj, pathArg)
);

export const path = pathOr(undefined);

export const tail = arr => Array.prototype.slice.call(arr, 1);

const _nth = (i, arr) => arr[i];

export const head = arr => arr[0];

export const last = arr => arr[arr.length - 1];

export const cond = curry((rules, ob) => {
  const cur = rules[0];
  if (!cur) return undefined;
  return ifElse(cur[0], cur[1], cond(tail(rules)))(ob);
});

export const choose = curry((rules, ob) => {
  const cur = rules[0];
  if (!cur) return undefined;
  return cur[0](ob) ? cur[1] : choose(tail(rules))(ob);
});

export const length = prop("length");

export const strictUniq = arr =>
  reduce((acc, cur) => (acc.indexOf(cur) > -1 ? acc : [...acc, cur]), [])(arr);

export const any = curry((fn, arr) => {
  return arr.length === 0
    ? false
    : compose(fn, head)(arr)
      ? true
      : any(fn, tail(arr));
});

export const find = curry((fn, arr) => {
  return arr.length === 0
    ? undefined
    : fn(head(arr))
      ? head(arr)
      : find(fn, tail(arr));
});

const _findIndex = (fn, arr) => arr.findIndex(fn);
/**
 * Returns a function applying all arguments to a *manually* curried function.
 *
 * Behaves a bit strange (see tests), so keeping it private-ish
 **/
export const _uncurry =
  fn =>
  (...args) =>
    args.reduce((acc, cur) => {
      return acc(cur);
    }, fn);

/** Curriable functions **/

/**
 * Takes a function and two values and returns true if the values map
 * to the same value; false otherwise.
 */
const _eqBy = (fn, a, b) => _equals(fn(a), fn(b));

const _all = (fn, arr) =>
  reduce((acc, cur) => (acc ? fn(cur) : acc), true, arr);

const _none = (fn, arr) =>
  reduce((acc, cur) => (fn(cur) ? false : acc), true, arr);

const _append = (arg, arr) => [...((arr instanceof Array && arr) || []), arg];

const _test = (regex, string) => regex.test(string);

const _match = (regex, string) => string.match(regex) || [];

// https://raphacmartin.medium.com/deep-equality-in-javascript-objects-1eea8abb3649
/**
 *
 * @param a Object
 * @param b Object
 * @returns {boolean}
 */
function _equals(a, b) {
  if (!(a instanceof Object) || !(b instanceof Object)) return a === b;
  // if the number of keys is different, they are different
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }

  for (const key in a) {
    const a_value = a[key];
    const b_value = b[key];
    // If the value is an object, check if they're different objects
    // If it isn't, uses !== to check
    if (
      (a_value instanceof Object && !_equals(a_value, b_value)) ||
      (!(a_value instanceof Object) && a_value !== b_value)
    ) {
      return false;
    }
  }
  return true;
}

const _eqProps = (prop, a, b) => _equals(a[prop], b[prop]);

const _hasPath = (p, ob) => {
  if (p.length === 0) return true;
  return has(p[0], ob) && _hasPath(tail(p), prop(p[0], ob));
};

/** Associate prop with val in obj */
function _assoc(prop, val, obj) {
  var result = {};
  for (var p in obj) {
    result[p] = obj[p];
  }
  result[prop] = val;
  return result;
}

const _assocPath = (path, val, obj) => {
  if (path.length === 0) {
    return val;
  }
  var idx = path[0];
  if (path.length > 1) {
    var nextObj =
      !isNil(obj) && Object.prototype.hasOwnProperty.call(obj, idx)
        ? obj[idx]
        : Number.isInteger(path[1])
          ? []
          : {};
    val = assocPath(Array.prototype.slice.call(path, 1), val, nextObj);
  }
  if (Number.isInteger(idx) && Array.isArray(obj)) {
    var arr = [].concat(obj);
    arr[idx] = val;
    return arr;
  } else {
    return assoc(idx, val, obj);
  }
};

/**
 * Apply fun to all but first argN args */
const _consume =
  (fn, argN) =>
  (...args) => {
    return fn(...args.slice(argN));
  };

const _propEq = (key, value, ob) => _equals(prop(key, ob), value);

const _propSatisfies = (fn, key, ob) => fn(prop(key, ob));

const _pathEq = (key, value, ob) => _equals(path(key, ob), value);

const _has = (prop, ob) =>
  complement(isNil)(ob) && Object.prototype.hasOwnProperty.call(ob, prop);

const _tryCatch = (tryer, catcher) => arg => {
  try {
    return tryer(arg);
  } catch (e) {
    return catcher(e, arg);
  }
};

/**
  Call arr.map(fn)

  Note: differs from Ramda, in that the callback will receive both the item, the index and the list.

  If you want the callback function `fn` to read only the first parameter, wrap `fn` in `unary(fn)`.
 */
const _map = (fn, arr) => arr.map(fn);

/**
  Call arr.filter(fn)

  Note: differs from Ramda, in that the callback will receive both the item, the index and the list.

  If you want the callback function `fn` to read only the first parameter, wrap `fn` in `unary(fn)`.
 */
const _filter = (fn, arr) => arr.filter(fn);

const _when = (predicate, whenTrueFn, arg) =>
  predicate(arg) ? whenTrueFn(arg) : arg;

const _ifElse = (predicate, whenTrueFn, whenFalseFn, arg) =>
  predicate(arg) ? whenTrueFn(arg) : whenFalseFn(arg);

const _either = (fn1, fn2, arg) => fn1(arg) || fn2(arg);

const _allPass = (fns, arg) =>
  fns.reduce((mem, cur) => (!mem ? false : cur(arg)), true);

const _anyPass = (fns, arg) =>
  fns.reduce((mem, cur) => (mem ? true : cur(arg)), false);

const _both = (fn1, fn2, arg) => _allPass([fn1, fn2], arg);

const _complement = (fn, arg) => !fn(arg);

const _includes = (item, c) => c.includes(item);

const _lte = (first, second) => first <= second;

const _lt = (first, second) => first < second;

const _gte = (first, second) => first >= second;

const _gt = (first, second) => first > second;

/**
  Call arr.reduce(fn, into);

  Note: differs from Ramda, in that the callback will receive the
  accumular, the item, the index and the list.

  If you want the callback function `fn` to read only the first two
  parameters, as with Ramda, wrap `fn` in `binary(fn)`.
 */
const _reduce = (fn, into, arr) => arr.reduce(fn, into);

const _tap = (fn, value) => (fn(value), value);

const _groupWith = (fn, arr) =>
  reduce(
    (acc, cur, index, arr) =>
      index === 0 || !fn(cur, arr[index - 1])
        ? [...acc, [cur]]
        : [...acc.slice(0, acc.length - 1), [...acc[acc.length - 1], cur]],
    []
  )(arr);

const _groupBy = (fn, arr) =>
  reduce((acc, cur) => over(lensProp(fn(cur)), append(cur), acc), {})(arr);

const _nthArg = (n, arg1, ...args) => [arg1, ...args][n];

/** Dissociate props from obj */
const _omit = (props, obj) => {
  var result = {};
  for (var p in obj) {
    if (!props.includes(p)) result[p] = obj[p];
  }
  return result;
};

const _pickBy = (pred, obj) => {
  var result = {};
  for (var k in obj) {
    if (pred(obj[k], k)) result[k] = obj[k];
  }
  return result;
};

const _pick = (props, obj) => {
  var result = {};
  props.forEach(prop => {
    if (prop in obj) result[prop] = obj[prop];
  });
  return result;
};

const _pickAll = (props, obj) => {
  var result = {};
  props.forEach(prop => {
    result[prop] = obj[prop];
  });
  return result;
};

function _dissoc(prop, obj) {
  return _omit([prop], obj);
}

const _infichain = (fns, arg) =>
  fns.reduce(
    (acc, cur, index) => (index === 0 ? cur(arg) : cur(acc, arg)),
    null
  );

const _mergeRight = (ob1, ob2) => Object.assign({}, ob1, ob2);

const _mergeLeft = (ob1, ob2) => Object.assign({}, ob2, ob1);

const _strictWithout = (items, list) =>
  list.reduce((acc, cur) => (items.includes(cur) ? acc : [...acc, cur]), []);

const _unary = fn => arg => fn(arg);

const _binary = fn => (arg1, arg2) => fn(arg1, arg2);

const _strictDifference = (list1, list2) => {
  const out = new Set();
  const s1 = new Set(list1);
  const s2 = new Set(list2);
  for (let item of s1) {
    if (!s2.has(item)) out.add(item);
  }
  return [...out];
};

const _mapWithRest = (fn, arr, rest1, ...rest) =>
  arr.map((cur, i, list) => fn(cur, i, list, rest1, ...rest));

const _andThen = (fn, promise) => Promise.resolve(promise).then(fn);
const _otherwise = (fn, promise) => promise.then(null, fn);

/**
 * @param {Funcion} f f(await g(val), val)
 * @param {Funtion} g g(val) => Promise
 * @return Promise
 */
const _fChain = (f, g, val) => g(val).then(x => f(x, val));

const _chain = (f, g, val) => f(g(val), val);

const _juxt = (arr, arg1, ...argN) => {
  return arr.map(fn => fn(arg1, ...argN));
};

const _slice = (from, to, arr) => arr.slice(from, to);

export const toLower = s => s.toLowerCase();
export const toUpper = s => s.toUpperCase();

export const maybeNumber = string =>
  Number.isInteger(Number(string)) ? Number(string) : string;

export const all = curry(_all),
  nthArg = curry(_nthArg),
  andThen = curry(_andThen),
  append = curry(_append),
  apply = curry(_apply),
  assoc = curry(_assoc),
  assocPath = curry(_assocPath),
  anyPass = curry(_anyPass),
  both = curry(_both),
  consume = curry(_consume),
  chain = curry(_chain),
  strictDifference = curry(_strictDifference),
  dissoc = curry(_dissoc),
  eqProps = curry(_eqProps),
  equals = curry(_equals),
  filter = curry(_filter),
  has = curry(_has),
  hasPath = curry(_hasPath),
  juxt = curry(_juxt),
  map = curry(_map),
  nth = curry(_nth),
  otherwise = curry(_otherwise),
  propEq = curry(_propEq),
  propSatisfies = curry(_propSatisfies),
  tryCatch = curry(_tryCatch),
  when = curry(_when),
  ifElse = curry(_ifElse),
  either = curry(_either),
  allPass = curry(_allPass),
  complement = curry(_complement),
  includes = curry(_includes),
  infichain = curry(_infichain),
  mapWithRest = curry(_mapWithRest),
  mergeLeft = curry(_mergeLeft),
  mergeRight = curry(_mergeRight),
  omit = curry(_omit),
  pathEq = curry(_pathEq),
  pick = curry(_pick),
  pickBy = curry(_pickBy),
  pickAll = curry(_pickAll),
  lt = curry(_lt),
  lte = curry(_lte),
  fChain = curry(_fChain),
  gt = curry(_gt),
  gte = curry(_gte),
  reduce = curry(_reduce),
  groupBy = curry(_groupBy),
  groupWith = curry(_groupWith),
  tap = curry(_tap),
  eqBy = curry(_eqBy),
  lens = curry(Lenses.lens),
  over = curry(Lenses.over),
  set = curry(Lenses.set),
  view = curry(Lenses.view),
  test = curry(_test),
  match = curry(_match),
  none = curry(_none),
  findIndex = curry(_findIndex),
  slice = curry(_slice),
  strictWithout = curry(_strictWithout),
  unary = curry(_unary),
  binary = curry(_binary),
  lensPath = p => Lenses.lens(path(p), assocPath(p)),
  lensProp = p => Lenses.lens(prop(p), assoc(p));

export const uncurried = {
  all: _all,
  apply: _apply,
  append: _append,
  assoc: _assoc,
  assocPath: _assocPath,
  both: _both,
  filter: _filter,
  propEq: _propEq,
  consume: _consume,
  tryCatch: _tryCatch,
  when: _when,
  ifElse: _ifElse,
  either: _either,
  allPass: _allPass,
  complement: _complement,
  includes: _includes,
  lt: _lt,
  lte: _lte,
  gt: _gt,
  gte: _gte,
  has: _has,
  hasPath: _hasPath,
  tap: _tap,
  groupBy: _groupBy,
  groupWith: _groupWith,
  nth: _nth,
  eqBy: _eqBy,
  eqProps: _eqProps,
  equals: _equals,
  omit: _omit,
  pathEq: _pathEq,
  pick: _pick,
  pickBy: _pickBy,
  pickAll: _pickAll,
  dissoc: _dissoc,
  test: _test,
  match: _match,
  mergeLeft: _mergeLeft,
  mergeRight: _mergeRight,
  strictWithout: _strictWithout,
  unary: _unary,
  binary: _binary,
  slice: _slice,
  findIndex: _findIndex,
  toLower,
  toUpper,
  ...Lenses
};
