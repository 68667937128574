/* odt-to-pdf:
 *
 * Request server to serve pdf variant document instead of the
 * (half-product) original by adding &fmt=pdf to the end and setting
 * the mimetype.
 *
 * Note this only gives the desired effect with server >= 4.3
 *
 * Author: Niels Giesen
 * Copyright 2017 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        $.each(data.groups, function (_, group) {
          $.each(group.controls, function (_, control) {
            if (
              control.controltype === "linklabel" &&
              control.isreport &&
              /^[^?]+\.odt\?/.test(control.url)
            ) {
              control.url += "&fmt=pdf";
              control.mimetype = {
                mimetype: "application/pdf",
                ext: "pdf"
              };
            }
          });
        });
      }
    });
  });
})(jQuery, window, document);
