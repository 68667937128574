/* progressbar:
 *
 * Show progressbar after step=next
 *
 * Author: Niels Giesen
 * Copyright 2011, 2013, 2015 Berkeley Bridge
 *
 */

import { bb } from "$json";
(function ($) {
  var timer;

  $(document).on("bb:preStep", function (event, _) {
    document.body.setAttribute("aria-busy", true);
    timer = window.setTimeout(function () {
      if (bb.ajax.busy()) {
        $(".progress").fadeIn();
        bb.Mode.set("busy");
      }
    }, 700);
  });

  $(document).on("bb:postHandleData bb:jsonError", function (event, data) {
    window.clearTimeout(timer);
    $(".progress").fadeOut(100);
    document.body.setAttribute("aria-busy", false);
    bb.Mode.unset("busy");
  });
})(jQuery);
