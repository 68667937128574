/* share:
 *
 * Share deeplink to model
 *
 * Author:
 * Copyright 2015 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var _link, _modelname;

  function windowspecs() {
    var w = 480,
      h = 380,
      x = Number((win.screen.width - w) / 2),
      y = Number((win.screen.height - h) / 2);
    return (
      "width=" +
      w +
      ",height=" +
      h +
      ",left=" +
      x +
      ",top=" +
      y +
      ",scrollbars=no,toolbar=no,resizable=yes,status=0"
    );
  }

  function getlink(modelname) {
    return (
      win.location.href +
      // Add bb.html to trailing slash
      (/\/$/.test(win.location.pathname) ? "bb.html" : "") +
      "?modelname=" +
      modelname
    );
  }

  $(function () {
    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.modelname) {
        _modelname = data.modelname;
        _link = getlink(_modelname);
        $(".bb-p-share-link").each(function () {
          $(this)
            .val(getlink(_modelname))
            .css("width", $(this).val().length * 6.75 + "px");
        });
      }
    });
  });

  $(doc)
    .on("click", ".bb-p-share", function (ev) {
      $(this).next(".bb-p-share-link").toggleClass("hidden");
      return false;
    })
    .on("click", ".bb-p-share-facebook", function (ev) {
      var f = "https://www.facebook.com/share",
        p =
          ".php?src=bm&v=4&i=1434464511&u=" +
          encodeURIComponent(_link) +
          "&t=" +
          encodeURIComponent(_modelname);
      if (!window.open(f + "r" + p, "Share with Facebook", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-gplus", function (ev) {
      var f = "https://plusone.google.com/_/+1/confirm?hl=en&url=",
        p =
          encodeURIComponent(_link) +
          "&title=" +
          encodeURIComponent(_modelname);
      if (!win.open(f + p, "Share with Google", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-linkedin", function (ev) {
      var f = "http://www.linkedin.com/shareArticle?mini=true&url=",
        p =
          encodeURIComponent(_link) +
          "&title=" +
          encodeURIComponent(_modelname) +
          "&source=" +
          encodeURIComponent(win.location.host);
      if (!win.open(f + p, "Share with LinkedIn", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-twitter", function (ev) {
      var f = "http://twitter.com/share?url=",
        p =
          encodeURIComponent(_link) + "&text=" + encodeURIComponent(_modelname);
      if (!win.open(f + p, "Share with Twitter", windowspecs()))
        win.location.href = f + p;
    });
})(jQuery, window, document);
