/* global $ */
import { stringSaysDo } from "./baby-lisp-apply.js";
import { BooleanBucket } from "./booleanbucket.js";

class BucketWithConsequences extends BooleanBucket {
  /**
   * @param {String} key Key to toggle
   * @param {Boolean} [bool] New value
   * @param {Boolean} [reflect] Reflect the key as a class in the <body>

   * Will hide or show any element which has <key> somewhere inside data-when or data-hide-when attribute.
   * Whether or not to show or hide is based on the parsing of those attributes.
   *
   * See baby-lisp.test.js for examples.
   */
  toggle(mode, bool, reflect = true) {
    if (mode.includes('"')) {
      console.warn(
        "Mode contains a double quote, that is not allowed - used in querySelectorAll"
      );
      return this;
    }
    const [changedOrAdded, bool2] = super.toggle(mode, bool);
    // Refrain from unnecessary redraw (by checking previous value):
    if (changedOrAdded) {
      $(document).trigger("bb:mode:" + mode, bool2);
      if (reflect) $("body").toggleClass(mode, bool2);
      {
        [
          ...document.querySelectorAll(
            '[data-when*="' + mode + '"], [data-hide-when*="' + mode + '"]'
          )
        ].forEach(elt => {
          let hidden = false;
          const hideWhen = elt.dataset["hideWhen"];
          if (hideWhen) {
            hidden = stringSaysDo(this, hideWhen);
          }
          // hideWhen is absent or yields false, so still shown, and
          // when becomes the judge:
          if (!hidden) {
            const when = elt.dataset["when"];
            // If there is no when, still show.
            if (when) {
              hidden = !stringSaysDo(this, when);
            }
          }
          elt.hidden = hidden;
        });
      }
    }
    return this;
  }
}

const Mode = new BucketWithConsequences();

export { Mode };
