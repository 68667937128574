import { registerWidget } from "$json/lib/form-widgets";
import { wControl } from "$json/lib/control";
import { _ } from "$json/lib/gettext";
/* grids:
 *
 * Register grid type 'grid-tabs' when font-class reads 'grid_tabs'.
 *
 * Author: Niels Giesen
 * Copyright 2012, 2014 Berkeley Bridge
 *
 */
(function ($, doc, win) {
  $(function () {
    $(document).on("bb:preHandleData", function (event, data, status, req) {
      if (typeof data != "undefined" && data && data.groups)
        data.groups = $.map(data.groups, function (group) {
          group.controls = $.map(group.controls, function (control) {
            if (
              control.controltype === "grid" &&
              control["font-class"] === "grid_tabs"
            ) {
              control.controltype = "grid-tabs";
            }
            return control;
          });
          return group;
        });
    });
  });

  var render = function (control, widget, group) {
    var tabs = $('<div class="tabs"></div>'),
      deletebutton = "",
      addbutton = "";
    var label,
      labelidx,
      i = 0,
      sel_idx = 0,
      sel_tab;
    while (labelidx === undefined && control.columns[i]) {
      var controltype = control.columns[i].controltype;
      if (controltype !== "checkbox" && controltype !== "radiobutton") {
        labelidx = i;
      }
      i++;
      if (labelidx >= control.columns.length) break;
    }
    if (typeof labelidx === "undefined") labelidx = 0;
    $.each(control.value, function (i, row) {
      deletebutton = "";
      if (group.current && control.deleteallowed)
        deletebutton =
          '<button tabindex=0 class="delete-x" name="update" value="' +
          control.name +
          "." +
          i +
          "." +
          "-" +
          '">×</button>';
      var tab = $(
        '<div class="tab tab-' +
          i +
          '" data-idx="' +
          i +
          '"><span>' +
          row[labelidx].value +
          "</span>" +
          deletebutton +
          "</div>"
      );
      if (!sel_idx && row[labelidx].value === "") {
        sel_idx = i;
        tab.find("span").text("(" + _("new") + ")");
        sel_tab = tab;
      } else tabs.append(tab);
    });
    tabs.append(sel_tab);
    if (group.current && control.addallowed) {
      addbutton =
        '<button tabindex=0 class="add" name="update" value="' +
        control.name +
        encodeURI("." + control.value.length + ".+") +
        '">+</button>';
      tabs.append('<div class="tab_plus">' + addbutton + "</div");
    }
    widget.append(tabs);
    $.each(control.value, function (i, row) {
      var card = $(
        '<table class="card card-' + i + '" data-idx="' + i + '"></table>'
      );
      widget.append(card);
      tabs.find(".tab-" + i).data("card", card);
      $.each(row, function (ii, cell) {
        var rowhtml = $("<tr>" + "</tr>"),
          cellhtml = $("<td>" + "</td>");
        card.append(rowhtml);
        rowhtml.append(
          '<td><label for="' +
            control.id +
            "-" +
            i +
            "-" +
            ii +
            '"' +
            (control.columns[ii].notnull ? 'class="bb-for-required"' : " ") +
            ">" +
            (control.columns[ii].colname || "") +
            "</label></td>"
        );
        rowhtml.append(cellhtml);
        var column = control.columns[ii];
        wControl(
          $.extend(
            column,
            { id: control.id + "-" + i + "-" + ii },
            $.isPlainObject(cell) ? cell : { value: cell }
          ),
          group,
          cellhtml //append hereto
        );
      });
    });
    widget.find(".tab-" + sel_idx + ", .card-" + sel_idx).addClass("selected");
    widget
      .find(".card tr:nth-child(" + labelidx + 1 + ") input")
      .on("change", function () {
        var idx = $(this).parents(".card").data("idx");
        $(this)
          .parents("*[data-type=grid-tabs]")
          .find(".tab-" + idx)
          .find("span")
          .text($(this).val());
      });
  };

  $(doc).on("click", ".tab", function () {
    $(this).addClass("selected").siblings().removeClass("selected");
    $(this)
      .data("card")
      .addClass("selected")
      .siblings()
      .removeClass("selected");
  });

  registerWidget({
    name: "grid-tabs",
    tagName: "div",
    attribs: () => ({}),
    render: render
  });
})(jQuery, document, window);
