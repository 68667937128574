/* back-to-top:
 *
 * The infamous back to top button
 *
 * Author:
 * Copyright 2015 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    // hide #back-top first
    $("#back-to-top").hide();

    // fade in #back-to-top
    $(function () {
      $(win).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $("#back-to-top").fadeIn();
        } else {
          $("#back-to-top").fadeOut();
        }
      });

      // scroll body to 0px on click
      $("#back-to-top a").click(function () {
        $("body,html").animate(
          {
            scrollTop: 0
          },
          800
        );
        return false;
      });
    });
  });
})(jQuery, window, document);
