/*
 * Show preview documents
 *
 * See readme.md in this directory.
 *
 * Author: Niels Giesen
 * Copyright (C) 2014-2019 by Berkeley Bridge
 *
 */
import { fromApiServer } from "$json/lib/location";
import { gt, _, bb } from "$json";
import Mustache from "$mustache";
let $ = jQuery;
const config = bb.propFinder(bb.conf, "arbitrary.previews");

// Text that may be shown when there is no default preview available.
const fallBackText = config("fallbacktext");
let _snips = [];

const styleElt = config => {
  let s = document.createElement("style");
  s.textContent = `
html {
  height: 100%;
}
${config(
  "iframe.style",
  `
html {
  background-color: transparent;
}
.bb-path {
  box-shadow: none;
  padding: 0;
  max-width: 100%;
}
.bb-p-preview-new {
  border-left: 10px solid #189bd8;
  padding-left: 10px;
}`
)}
`;
  return s;
};

let iframeStyle = styleElt(config);

let uncached = url => url + "&_nocache=" + new Date().getTime();

gt.addTranslations({
  // Actually means no preview could be loaded (not in the correct format or some other error).
  "no preview available": {
    nl: "geen preview beschikbaar",
    fr: "aucune prévisualisation disponible",
    de: "keine Vorschau verfügbar",
    ru: "предварительный просмотр недоступен"
  },
  "open this preview in a new window": {
    nl: "open deze preview in een apart venster",
    fr: "ouvrir cette prévisualisation dans une autre fenêtre",
    de: "öffne diese Vorschau in einem neuem Fenster",
    ru: "откройте предварительный просмотр в новом окне"
  }
});

function compareSnippets($container, _snips) {
  const snippets = $container.find("h1,h2,h3,h4,h5,h6,p,ul");
  let num = 0;
  const tmpsnips = [];
  $.each(snippets, function () {
    let snip = this.innerHTML;
    if (_snips.indexOf(snip) === -1) {
      this.classList.add("bb-p-preview-new");
      if (++num == 1) $container.scrollTo(this);
    }
    tmpsnips.push(snip);
  });
  return tmpsnips;
}

const removeEmptyItems = node => {
  let items = node.querySelectorAll("li, p");
  for (let i = items.length; i > 0; --i) {
    let item = items[i - 1];
    if ($.trim(item.textContent) === "") {
      if (!item.nextElementSibling && !item.previousElementSibling)
        item.parentNode.parentNode.removeChild(item.parentNode);
      else item.parentNode.removeChild(item);
    }
  }
};

const onLoadHandler = (iframe, portion, config) => () => {
  const doc = iframe.contentDocument;
  if (!doc.querySelector(portion)) {
    bb.Mode.unset("hasTextInside");
  } else {
    bb.Mode.set("hasText");
    bb.Mode.set("hasTextInside");
    removeEmptyItems(doc.body);
    doc.head.appendChild(iframeStyle);
    if (config("showChanges")) {
      _snips = compareSnippets($(doc), _snips);
    }
    if (config("iframe.resize") && "IntersectionObserver" in window) {
      let options = {
        root: doc.body.parentElement,
        rootMargin: "0px",
        threshold: 1.0
      };
      var callback = function (entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio == 1) {
          } else {
            let rect = doc.body.getBoundingClientRect();
            if (rect.height) iframe.style.height = `${rect.height + 200}px`;
          }
        });
      };
      let observer = new IntersectionObserver(callback, options);
      observer.observe(doc.body);
    }
    //   Previews.trigger('reloaded', [ doc, iframe ]);
  }
};

(function (win, doc) {
  $(function () {
    var _document; // which document?
    var _textURL; // which url?
    var _outside = false;
    var $container = $("#bb-p-preview");
    let iframe;

    if (config("iframe.on")) {
      iframe = document.createElement("iframe");
      iframe.setAttribute("class", "p-previews-frame");
    }

    /* Remove all notion of current preview
     */
    function cleanUp(all) {
      bb.Mode.unset("hasText");
      bb.Mode.unset("hasTextInside");
      // Following line seems to have been too blunt:
      all && bb.Mode.unset("hasPreviews");
      $container.empty();
      _textURL = undefined;
      _document = undefined;
    }

    /* reload
     * @param (String) href: (optional) URL, Will use cached URL if omitted.
     * @param (Srting) document: (optional) Template name. Cache it so
     * we can check its validity later on.
     */
    function reload(url, document, portion) {
      if (url) _textURL = url;
      if (document) _document = document;
      if (_textURL) loadDoc(_textURL, portion);
    }

    let loadInside = config("iframe.on")
      ? (url, portion) => {
          bb.Mode.set("hasTextInside");
          $container.empty();
          $container.append(iframe);
          iframe.src = uncached(url);
          var handler = onLoadHandler(iframe, portion, config);
          iframe.onload = handler;
        }
      : (url, portion) => {
          $container.load(
            url + "&_nocache=" + new Date().getTime() + ` ${portion}`,
            function (responseText) {
              // If container is empty, loading has apparently failed.
              if ($container.find(portion).length === 0) {
                // cleanUp()
                $container.text(_("no preview available"));
                bb.Mode.unset("hasTextInside");
              } else {
                bb.Mode.set("hasTextInside");
                var btn = $(outsidebutton);
                $container.prepend(btn);
                btn.on("click", function () {
                  loadOutside(url);
                });
                if (config("showChanges")) {
                  _snips = compareSnippets($container, _snips);
                }
                removeEmptyItems($container.get(0));
                $(doc).trigger("bb-p:previews-rendered", {
                  $container,
                  responseText
                });
              }
            }
          );
        };

    function loadDoc(url, portion) {
      if (!url) return;
      if (!portion) portion = "#preview";
      bb.Mode.set("hasText");

      $('#bb-p-previews a[href="' + url + '"]')
        .addClass("selected")
        .siblings()
        .removeClass("selected");

      if (!_outside) {
        loadInside(fromApiServer(url), portion);
      }
      if (_outside) {
        loadOutside(fromApiServer(url));
      }
    }

    function loadOutside(url) {
      _outside = true;
      bb.Mode.unset("hasTextInside");
      $container.empty();

      var w = win.open(
        url + "&_nocache=" + new Date().getTime(),
        "Preview",
        "resizable=yes,scrollbars=yes",
        true
      );
      if (!w.closed)
        var watchClose = win.setInterval(function () {
          try {
            if (w.closed) {
              win.clearTimeout(watchClose);
              //Do something here...
              _outside = false;
              bb.Mode.set("hasTextInside");
              reload();
            }
          } catch (e) {}
        }, 200);
    }

    $(document).on("click", "#bb-p-previews a", function () {
      if (!$container.length) return true;
      reload(this.getAttribute("href"), this.getAttribute("data-document"));
      return false;
    });

    var tmpl = $("#bb-p-previews-template").html() || "";
    var outsidetmpl = $("#bb-p-preview-outside-template").html();
    if (!outsidetmpl) {
      outsidetmpl =
        '<button class="bb-p-preview-outside"' +
        ' title="[[title]]"' +
        '">' +
        '<span class="a-offscreen">' +
        "[[title]]" +
        " </span>" +
        '<span aria-hidden="true">↗</span>' +
        "</button>";
    }
    Mustache.tags = ["[[", "]]"];
    var outsidebutton = Mustache.render(outsidetmpl, {
      title: _("open this preview in a new window")
    });

    // Each navigatory action w/ documents re-renders links and fetches the document.
    $(document).on("bb:postHandleData", function (event, data) {
      if (!data.documents) {
        if (
          (data && data.status && data.status === "logout successful") ||
          (data.models && data.models.length > 0)
        ) {
          cleanUp(true);
        }
        return;
      } else {
        if (data.documents.length > 0) {
          bb.Mode.set("hasPreviews");
          // Some plugins used to remove all but the first document => config option 'singular'.
          if (config("singular") === true) {
            data.documents = [data.documents[0]];
          }
        }
        var gHD = getHref(data);
        var selected = data.documents.find(function (doc) {
          return _document === doc.document;
          // The following is more expensive:
          // return (_textURL === (doc.href && doc.href() || gHD.bind(doc)()))
        });
        // autoload: true: load first model-level document.
        // autoload: 'any': load first document, period (could be audit trail).
        if (!selected && config("autoload")) {
          selected = data.documents.find(function (doc) {
            if (config("autoload") === "any") return true;
            if (config("autoload") === true) return !doc.href; // Set by previews-include-audit-trail.
          });
        }
        if (selected) {
          selected.selected = true;
          _document = selected.document;
          _textURL = (selected.href && selected.href()) || gHD.bind(selected)();
        } else {
          cleanUp();
          if (fallBackText) $container.text(fallBackText);
        }

        var templatedata = {
          documents: data.documents,
          name: function () {
            return this.document.replace(/_/g, " ");
          },
          breakablename: function () {
            return this.document.replace(/_/g, " ");
          },
          href: getHref(data)
        };
        $("#bb-p-previews").html(Mustache.render(tmpl, templatedata));

        if (selected) {
          reload();
        }
      }
    });

    function getHref(data) {
      return function () {
        return (
          "preview?" +
          "dbname=" +
          data.dbname +
          "&sessionid=" +
          data.sessionid +
          "&uniqueid=" +
          data.uniqueid +
          "&template=" +
          this.document
        );
      };
    }

    /**
     * Export loadDoc function
     */
    bb.Plugins = $.extend({}, bb.Plugins, {
      previews: {
        loadDoc: loadDoc,
        reload: reload
      }
    });
  });
})(window, document);
