const Identity = x => ({ x, map: fn => Identity(fn(x)) });

const Const = x => ({ x, map: fn => Const(x) });

const lens = (getter, setter) => functor => target =>
  functor(getter(target)).map(focus => setter(focus, target));

const over = (lens, fn, obj) => lens(x => Identity(fn(x)))(obj).x;

const set = (lens, val, obj) => over(lens, () => val, obj);

const view = (lens, obj) => lens(Const)(obj).x;

export { lens, over, set, view };
