/* chapters:
 *
 * Fill multiple chapter-related stuff using Mustache templates.
 *
 * Associate any .p-chapters with a Mustache template identified by
 * the data-p-chapters-template attribute.
 *
 * The Mustache template receives an object with the following
 * elements:
 *
 * "chapters" : Array of enhanced chapters (with _allowed,
 * _forbidden, _done and _current to use as switches)
 *
 * "size" : Total size of chapters array
 * "done" : Number of completed chapters
 *
 * @dependencies: mustache
 *
 * NB
 * Your template must use class structure for buttons as follows:
 *
 * class="p-chapters-chapter p-chapters-chapter--[[status]]"
 *
 * Otherwise, the forbidden chapters will be accessible.
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
import { bb } from "$json";
import { Mustache } from "$mustache";
(function ($, win, doc) {
  $(function () {
    var hash = {};
    Mustache.tags = ["[[", "]]"];

    $(document).on("bb:postHandleData", function (event, data) {
      if (data && data.chapters && data.chapters.length) {
        $.each(data.chapters, function (_, ch) {
          ch._allowed = ch.status === "allowed";
          ch._forbidden = ch.status === "forbidden";
          ch._done = ch.status === "done";
          ch._current = ch.status === "current";
        });
        //
        replace(document.querySelectorAll(".p-chapters"), data.chapters);
        bb.Mode.set("hasChapters");
      } else if (data.groups || !bb.Mode.get("hasModel")) {
        bb.Mode.unset("hasChapters");
      }
    });

    function run() {
      if ($(this).data("status") !== "forbidden") {
        console.log(this, $(this).data("chapter"));
        bb.gotonode($(this).data("chapter"));
      }
    }

    $(".p-chapters").on(
      "click",
      ".p-chapters-chapter:not(.p-chapters-chapter--forbidden)",
      run
    );

    function replace(nodes, chapters) {
      for (var i = 0; i < nodes.length; ++i) {
        var elt = nodes[i],
          tmplid = elt.getAttribute("data-p-chapters-template"),
          tmpl = hash[tmplid] || (tmplid && $("#" + tmplid).html()),
          size = chapters.length,
          done = chapters.filter(function (ch) {
            return ch.status === "done";
          }).length,
          percentage = Math.round((done / size) * 100),
          contents =
            tmpl &&
            Mustache.render(tmpl, {
              size: size,
              done: done,
              percentage: percentage,
              chapters: chapters
            });
        if (contents === undefined) {
          console.warn(
            "Mustache template #" + tmplid + " not found, referred to by ",
            elt
          );
          return;
        }
        // Save reference for later use
        if (!hash[tmplid]) {
          hash[tmplid] = tmpl;
        }
        elt.innerHTML = contents;
      }
    }
  });
})(jQuery, window, document);
