/* info-relative:
 *
 * Change hover events for informationsources to click (toggle)
 * events, and put the information beneath the question to which it
 * belongs, pushing subsequent questions down.q
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2013 by Berkeley Bridge
 *
 */

import { bb } from "$json";

(function ($) {
  function hideHintImmediately() {
    bb.Mode.unset("hasToolTip");
    $(".bb-tooltip-body").html("");
    $(".bb-tooltip-body.global").addClass("empty");
    var h = $("#bb-balloon").hide().get(0);
    if (h) h.style.width = "";

    $(".group.selected .hinted").data("tooltipshown", false);
  }

  function resetInfoEvents() {
    $(document).off(".bb-info");

    $(document).on("click", ".group.selected .hinted", function () {
      var $this = $(this);
      if (!$this.data("tooltipshown")) {
        var forcetop = $this.get(0).tagName === "SELECT";
        var reference = $this.is(".bb-questionlabelgroup")
          ? $this
          : $this.is(":not(.bb-questionlabelgroup *)")
            ? $this.next("span")
            : $this.parents(".bb-questionlabelgroup");

        if ($(".bb-tooltip-body.tied:not(#bb-p-hintsbar *)").length > 0)
          reference.after($(".bb-tooltip-body.tied:not(#bb-p-hintsbar *)"));
        else reference.after($('<div class="bb-tooltip-body tied"></div>'));
        $("#bb-p-hintsbar .bb-tooltip-body.tied").css(
          "top",
          $this.offset().top
        );
        $(".bb-tooltip-body.tied").html(bb.escapeHTML($this.data("tooltip")));
        bb.Mode.set("hasToolTip");
        $(".hinted").data("tooltipshown", false);
        $this.data("tooltipshown", true);
      } else {
        $this.data("tooltipshown", false);
        hideHintImmediately();
      }
      return false;
    });

    $(document).on(
      "click.bb-info touchstart.bb-info",
      ":not(.bb-freetext, .hinted)",
      hideHintImmediately
    );
    // $(document).on('click.bb-info touchstart.bb-info', '#bb-p-hintsbar', hideHintImmediately);
    $(document).on(
      "click.bb-info touchstart.bb-info",
      ".bb-freetext",
      function () {
        $(".bb-tooltip-body.global")
          .html(decodeURI($(this).find(".bb-freetext-content").html()))
          .removeClass("empty");
        // $(this).setToolTipPosition();
        bb.Mode.set("hasHintsBar");
        return false;
      }
    );
  }

  $(resetInfoEvents);

  $(document).on("bb:postHandleData", function (event, data, status, req) {
    if (data && data.groups) {
      $(".hinted").each(function () {
        var $this = $(this);
        var tt = $this.data("tooltip");
        var group = $this
          .parents(".bb-questionlabelgroup")
          .add($this.parent("label"))
          .filter(":nth(0)");
        if (group.length > 0) {
          $('<a class="hinted"></a>')
            .data("tooltip", tt)
            .prependTo(group.addClass("bb-p-info-relative-got-hint"));
          // group.data('tooltip', tt).addClass('hinted');
          $this.removeClass("hinted");
        }
      });
    }
  });
})(jQuery, window);
