import { bb } from "$json";
/* expanding-headers:
 *
 * Headers should expand checkboxes that follow
 *
 * Author: Niels Giesen
 * Copyright 2013 Berkeley Bridge
 *
 */
(function ($) {
  $(function () {
    var prefix = "bbm";
    try {
      if (bb.conf.plugins.indexOf("form-group") > -1) {
        prefix = "bb-g";
      }
    } catch (e) {
      // let go;
    }

    $(document).on("bb:postHandleData", function (event, data) {
      // Is stuff already checked?
      $.each(
        $(".group ." + prefix + "-expanding-header"),
        function (i, header) {
          var shouldcollapse = !$(header)
            .nextUntil(".group ." + prefix + "-expanding-header")
            .filter(":has(:checked)").length;
          if (shouldcollapse) {
            $(header)
              .nextUntil(
                "." +
                  prefix +
                  "-expanding-header, ." +
                  prefix +
                  "-expanding-header-stop"
              )
              .hide();
          } else {
            $(header).addClass("bb-p-expanding-headers-expanded");
          }
        }
      );
    });

    $(document).on(
      "click",
      ".group ." + prefix + "-expanding-header",
      function () {
        $(this)
          .toggleClass("bb-p-expanding-headers-expanded")
          .nextUntil(
            "." +
              prefix +
              "-expanding-header, ." +
              prefix +
              "-expanding-header-stop"
          )
          .slideToggle();
      }
    );
  });
})(jQuery);
