/* savecase:
 *
 * Download and upload cases
 *
 * Author: Niels Giesen
 * Copyright 2013 Berkeley Bridge
 *
 */
import { fromApiServer } from "$json/lib/location";
import { _, bb } from "$json";

(function ($, document) {
  $(function () {
    var $doc = $(document),
      $iframe = $(
        '<iframe id="bb-p-uploadert" style="display:none" name="bb-p-uploadert"></iframe>'
      ),
      $form = $(`<form
        style="display:none"
        target="bb-p-uploadert"
        action="${fromApiServer("loadcase?fmt=json")}"
        id="bb-p-loadcaseform"
        accept="text/plain"
        enctype="multipart/form-data"
        method="post"
      >
        <div>
          <input type="hidden" name="uniqueid" />
          <input type="hidden" name="dbname" />
          <input type="hidden" name="sessionid" />
          <input
            type="file"
            name="case"
            required="required"
            id="bb-loadcasefile"
            accept=".cas"
          />
          <p>${_("Choose the session file you saved earlier")}</p>
          <input name="add" type="submit" class="submit" value="${_("load")}" />
        </div>
      </form>`);
    // Memorize state vars
    var uid, sid, db;

    $iframe.appendTo("body").on("load", function () {
      var text = $(this.contentDocument.body).text();
      var gotJSON = true;
      try {
        var data = JSON.parse(text);
      } catch (e) {
        gotJSON = false;
      } finally {
        if (gotJSON) {
          $doc
            .trigger("bb:preHandleData", data)
            .trigger("bb:handleData", data)
            .trigger("bb:postHandleData", data);
        }
      }
    });

    $form
      .appendTo("body")
      .dialog({
        modal: true,
        autoOpen: false,
        minWidth: 300,
        title: _("load session")
      })
      .find("[type=submit]")
      .on("click", function () {
        $form.dialog("close");
        $(".progress").fadeIn();
        bb.Mode.set("busy");
      })
      .end()
      .find("[type=file]")
      .on("change", function () {
        $form.find("input[type=submit]").trigger("focus");
      });

    // Listen on the 'load button' that may be anywhere on the page
    $("[data-rel=load]")
      .on("keydown", function (ev) {
        if (ev.keyCode === 13) {
          // Dispatch to click handler on ENTER.
          $(this).trigger("click");
          return false;
        }
        return true;
      })
      .on("click", function () {
        $form.dialog("open");
      });

    $doc.on("bb:postHandleData", function (event, data) {
      if (data && data.uniqueid && data.sessionid && data.dbname) {
        if (
          uid !== data.uniqueid ||
          sid !== data.sessionid ||
          db !== data.dbname
        ) {
          uid = data.uniqueid;
          sid = data.sessionid;
          db = data.dbname;
          $("a[data-rel=save]").attr({
            href: fromApiServer(
              "savecase?" +
                $.param({
                  uniqueid: data.uniqueid,
                  sessionid: data.sessionid,
                  dbname: data.dbname
                })
            ),
            download:
              data.modelname +
              "." +
              data.sessionid +
              $.datepicker.formatDate("yymmdd", new Date()) +
              ".cas"
          });

          $form.find("input[type=hidden]").each(function () {
            var val = bb.getVar($(this).attr("name"));
            if (val) $(this).val(val);
          });
        }
      }
    });
    function escapeToggle(force = true) {
      let hints = document.querySelectorAll(".bb-p-savecase-hint");
      hints.forEach(hint => {
        hint.classList.toggle("escaped", force);
      });
    }
    // Additional esc key trap to hide hover/focus tips
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        escapeToggle(true);
        window.setTimeout(escapeToggle, 5e3, false);
      }
    });
  });
})(jQuery, document);
