/* horizontal-list:
 *
 * Add tag 'horizontal' to 'smallish' list controls (radiogroups and
 * checklists).
 *
 * 'Smallishness' here is a function of max_items and max_chars:
 *
 * The combined characters may amount to at most 25
 * Any option by itself may not be larger than nine characters.
 *
 * NOTE that this plugin performs an additive operation on the JSON
 * data object.
 *
 * Author: Niels Giesen
 * Copyright 2013, 2015 Berkeley Bridge
 *
 */
(function ($) {
  var max_chars = 25,
    max_chars_per_item = 9;

  $(function () {
    $(document).on("bb:preHandleData", function (event, data, status, req) {
      if (data && typeof data && data.groups) {
        $.map(data.groups, function (group) {
          $.map(group.controls, function (control) {
            if (
              control.controltype === "radio" ||
              control.controltype === "checkmultilist"
            ) {
              var len = control.value.length,
                small = true,
                chars = 0;
              for (var i = 0; i < len; i++) {
                if (
                  control.value[i].option.length > max_chars_per_item ||
                  ((chars += control.value[i].option.length), chars > max_chars)
                )
                  return;
              }
              control.tags = control.tags || [];
              control.tags.push("horizontal");
            }
          });
        });
      }
    });
  });
})(jQuery);
