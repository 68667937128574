/* skip:
 *
 * Stub to include skip button (or not)
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {});
})(jQuery, window, document);
