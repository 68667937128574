import { bb } from "$json";
/*
 * reset-password:
 *
 * This global plugin gives the theme
 * a reset link below the login form
 * which will link to the live/ html
 * - a theme ambiguous simple layer
 * handling all aspects of resulting
 * password changing methods.
 *
 * NB: Dependencies:
 *
 * There needs to be a plugin-specific
 * link hard-coded into the theme, e.g.:
 *

 {{#plugins.reset-password}}
 <tr>
   <td colspan="2">
     <a
       id="p-reset-password-link"
       href="plugins/reset-password/live/newpwd.html">
         I forgot my password...
     </a>
   </td>
 </tr>
 {{/plugins.reset-password}}

 * Author: Tim Bauwens
 * Copyright 2018 Berkeley Bridge
 *
 */

(function ($, win, doc) {
  $(doc).ready(function () {
    try {
      var lang = bb.conf.lang;
      var helpdesk =
        bb.conf.arbitrary &&
        bb.conf.arbitrary.resetpasswordHelpdesk != undefined
          ? bb.conf.arbitrary.resetpasswordHelpdesk
          : "";
      var theme =
        self == top ? window.location.href : "iframe+" + document.referrer;
      var href =
        "plugins/reset-password/live/newpwd.html" +
        "?theme=" +
        encodeURIComponent(theme) +
        "&lang=" +
        lang +
        "&helpdesk=" +
        encodeURIComponent(helpdesk);
      $("#p-reset-password-link").attr("href", href);
    } catch (e) {
      /*
        If we couldn't determine the theme or lang,
        it's not worth sending the user
        potentially back to the wrong place.
      */
      $("#p-reset-password-link").hide();
    }
  });
})(jQuery, window, document);
