/* bb-xarea:
 *
 * Dynamically grow textareas.
 *
 * Depends on: jquery.xarea.js
 *
 * Copyright 2013 Berkeley Bridge
 *
 */

(function ($) {
  var first = true;

  function xarea(event, data, status, req) {
    if (
      typeof data != "undefined" &&
      data &&
      data.groups &&
      data.groups.length > 0
    )
      $(".group textarea")
        .filter(function () {
          return !$(this).data("xarea");
        })
        .data("xarea", true)
        .on("bb:errorOn", function (ev, text, anchor) {
          var parent = $(this).parents(".xarea");
          var errordiv = parent.find(".errortext");
          parent.next(".errortext").remove();
          errordiv.insertAfter(parent);
        })
        .on("bb:errorOff", function (ev) {
          var parent = $(this).parents(".xarea");
          parent.next(".errortext").remove();
        })
        .xarea()
        .each(function () {
          this.parentNode.className = "xarea";
        });
  }

  $(document)
    .on("change keydown keyup", ".group textarea", function () {
      window.setTimeout(function () {
        $(document).trigger("bb:resized");
      }, 150);
    })
    .on("focus", ".xarea", function (ev) {
      $(this).addClass("focus");
      return true;
    })
    .on("blur", ".xarea", function (ev) {
      $(this).removeClass("focus");
    });

  $(document).on("bb:updated", function (event, $widget, control, updates) {
    if (
      updates.includes("visible") &&
      control.controltype === "memo" &&
      control.visible
    ) {
      $widget.trigger("bb:reclone");
    }
  });

  $(document).on("bb:postHandleData", function (event, data, status, req) {
    if (first) {
      window.setTimeout(function () {
        xarea(event, data, status, req);
      }, 150);
      first = false;
    } else {
      xarea(event, data, status, req);
    }
  });
})(jQuery);
