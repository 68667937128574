(function ($, win, document) {
  $(function () {
    $(document).on("bb:postHandleData", function (event, data) {
      if (data && data.groups) {
        data.groups.filte;
        var reports = document.querySelectorAll(".group.selected a.report");
        for (var i = 0; i < reports.length; ++i) {
          var l = document.createElement("link");
          l.rel = "prefetch";
          l.href = reports[i].href;
          reports[i].insertAdjacentElement("afterEnd", l);
        }
      }
    });
  });
})(jQuery, window, document);
