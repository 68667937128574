/* user-error:
 *
 * Do stuff on user error
 *
 * Author: Niels Giesen
 * Copyright 2011 Berkeley Bridge
 *
 */
import { bb, _ } from "$json";
(function ($) {
  bb.notify = function (string) {
    throw string;
    if (typeof console != "undefined" && console && console.log)
      console.log(string);
    $("#bb-message")
      .html(string)
      .append('<span class="bb-x">&times;</span>')
      .show();
  };

  $(document).on("click", ".bb-x", function () {
    $(this).parent().hide();
  });

  $(function () {
    $(document).on("bb:userError", function (event, text) {
      $(".bb-tooltip-body").html("").addClass("empty");
      bb.Mode.unset("hasToolTip");
      var h = $("#bb-balloon").hide().get(0);
      if (h) h.style.width = "";

      bb.notify(_(text));
    });
    $(window).on("scroll click keydown", function () {
      $("#bb-message").fadeOut();
    });
  });
})(jQuery);
