/* a11y-user-detect:
 *
 * "User Detect" will dynamically toggle between
 * a-keyboard-user & a-mouse-user (described in a11y.less)
 * depending on user interaction on document.
 *
 * The idea is to give keyboard users more visual queues,
 * without effecting the aesthetics for non-keyboard users.
 *
 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 */

import { Mode } from "$json/lib/mode";

export const isKeyboardUser = () => Mode.get("a-keyboard-user");

export const isMouseUser = () => Mode.get("a-mouse-user");

export const setKeyboardUser = () => {
  Mode.set("a-keyboard-user");
  Mode.unset("a-mouse-user");
};

export const setMouseUser = () => {
  Mode.unset("a-keyboard-user");
  Mode.set("a-mouse-user");
};

$(function () {
  let openingMatch, mouse;
  try {
    // Could have no opener, or opener could be X-Origin
    if (window.opener.document.body) {
      openingMatch = window.opener.document.body.className.match(
        /\ba-(keyboard|mouse)-user\b/
      );
      if (openingMatch) mouse = openingMatch[1] === "mouse";
    }
  } catch (e) {
    mouse = false;
  }
  if (mouse) {
    setMouseUser();
  } else {
    setKeyboardUser();
  }
  $(document.body).on("mousedown touchstart", function () {
    setMouseUser();
  });
  $(document.body).on("keydown", function (e) {
    if (
      e.key === "Tab" ||
      e.target.matches(`input[type="radio"], input[type="checkbox"]`)
    ) {
      setKeyboardUser();
    }
  });
});
