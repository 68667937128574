/* high-on-info:
 *
 * Make an information source from text in questions that matches the
 * description of an informationsource.
 *
 * Author: Niels Giesen
 * Copyright (C) 2011, 2012, 2015 by Berkeley Bridge
 *
 *
 */
import { bb, _ } from "$json";

(function ($) {
  function maybeEncodeURI(uri) {
    return uri.indexOf("%") > -1 ? uri : encodeURI(uri);
  }

  function replaceMatchingTextWithHTML(n, from, to) {
    if (typeof n == "undefined") return false;
    if (n.tagName === "A" /*Do not mess with links*/) return false;
    if (n.nodeType == 3 /*Node.TEXT_NODE*/) {
      if (n.data.match(from)) {
        var nn = document.createElement("span");
        $(nn).html(n.data.replace(from, to));
        n.parentNode.replaceChild(nn, n);
        return true;
      }
      return false;
    } else {
      var kids = n.childNodes;
      for (var i = 0, len = kids.length; i < len; i++) {
        if (replaceMatchingTextWithHTML(kids[i], from, to)) return true;
      }
      return false;
    }
  }

  function makeLinksInMainText(informationsources) {
    var source,
      from,
      to,
      description,
      content,
      isurl,
      isexternal,
      object = $(".group.selected").get(0),
      flags = "m";
    for (var i = 0, len = informationsources.length; i < len; i++) {
      source = informationsources[i];
      if (!source.selected) continue;
      description = source.description.replace(/[.?|()*]/gm, function ($0) {
        return "\\" + $0;
      });
      from = new RegExp("((?= )|^|\\b)" + description + "(\\b|$|(?= ))", flags);
      to = bb.escapeHTML(source.description);
      content = source.content;
      isurl = source.isurl;
      isexternal = source.external;
      if (isurl) {
        replaceMatchingTextWithHTML(
          object,
          from,
          "<a" +
            (isexternal ? ' target="_blank"' : "") +
            ' class="bb-' +
            (isexternal ? "external" : "embedded") +
            " " +
            (isexternal ? "bb-freetext" : "") +
            '"' +
            ' href="' +
            maybeEncodeURI(content) +
            '">' +
            to +
            (isexternal
              ? '<span class="bb-freetext-content">' +
                bb.escapeHTML(content.substring(0, 20)) +
                "..." +
                _(" (click to open link)") +
                "</span>"
              : "") +
            "</a>"
        );
      } else {
        var too_large = source.content.length > 500;
        var newtext =
          '<div class="bb-realcontent" data-all-content="' +
          escape(source.content) +
          '">' +
          (too_large
            ? bb
                .escapeHTML(source.content.substring(0, 500))
                .replace(/\n/gm, "<br/>") + "..."
            : bb.escapeHTML(source.content).replace(/\n/gm, "<br/>")) +
          "</div>" +
          (too_large
            ? '<a class="bb-popup-rest-of-freetext">' +
              _("click for more...") +
              "</span>"
            : "");

        replaceMatchingTextWithHTML(
          object,
          from,
          "<span" +
            ' class="bb-freetext">' +
            to +
            '<span class="bb-freetext-content' +
            (too_large ? " bb-too-large" : "") +
            '">' +
            encodeURI(newtext) +
            "</span></span>"
        );
      }
    }
  }

  $(document).on("bb:postHandleData", function (event, data, status, req) {
    if (data && data.informationsources) {
      var sources = $.grep(data.informationsources, function (source) {
        return (
          $.trim(source.description) != "" &&
          $.trim(source.content) != "" &&
          $.trim(source.content) != "http://"
        );
      });
      makeLinksInMainText(sources);
    }
  });
})(jQuery);
