import { bb } from "$json";
/* model-oneclick:
 *
 * Provide just one button per model
 *
 * Author: Niels Giesen
 * Copyright 2014 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  bb.createModelItem = function (model) {
    var dbname = model.dbname,
      mdl =
        "<li>" +
        '<button data-bb:dbname="' +
        dbname +
        '"' +
        ' class="bb-model bb-newcase ' +
        model._selectedclass +
        " bb-model-yours-" +
        model.yours +
        '">' +
        bb.escapeHTML(model._nicename) +
        "</button>" +
        "</li>";

    return mdl;
  };
})(jQuery, window, document);
