/* close-caselist:
 *
 * Adds closing cross on the case list
 *
 * Author: Annegreet Olijve
 *
 * Copyright (C) 2014, 2015 by Berkeley Bridge
 *
 */
import { gt, _ } from "$json";

var translations = {
  "Close case overview": {
    nl: "Sluit casusoverzicht",
    fr: "Fermez le table des cas",
    de: "Casusübersicht schließen",
    ru: "Всклучите резюме сессии"
  }
};

$(function () {
  gt.addTranslations(translations);
});

$(document).on("bb:postHandleData", function (event, data) {
  if (data && data.cases && data.models && data.models.length != 0) {
    // Server always selects model if there is but one model.
    var origcolspan = $("#bb-cases tr.wcasus td").first().attr("colspan");
    var newcolspan = origcolspan - 1;

    $("#bb-cases tr.wcasus td").first().attr({
      colspan: newcolspan,
      tabindex: 0
    });
    $("#bb-cases tr.wcasus")
      .first()
      .append(
        '<td aria-role="button" aria-label="' +
          _("Close case overview") +
          '" tabindex="0" class="bb-p-close-caselist-cross bb-openmodels">' +
          "<a>✕</a></td>"
      );
    $("#bb-cases thead").first().removeAttr("tabindex");
  }
});
