/* burger:
 *
 * Handle burger menu
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(doc).on("click", ".c-burger-wrap", function () {
    var id = this.getAttribute("aria-controls"),
      menu = doc.getElementById(id);
    if (this.getAttribute("data-expanded") === "false") {
      [this, menu].map(function (x) {
        x.setAttribute("data-expanded", "true");
      });
    } else {
      [this, menu].map(function (x) {
        x.setAttribute("data-expanded", "false");
      });
    }
    return false;
  });
})(jQuery, window, document);
