/* tag-too-wide:
 *
 * Tag interfaces (just tables for now) as too wide for their
 * container.
 *
 * This happens both on initial rendering and on resizing
 *
 * Author: Niels Giesen
 * Copyright 2014, 2016 Berkeley Bridge
 *
 */

import { bb } from "$json";
(function ($, win, doc) {
  function tagTables() {
    bb.Mode.unset("questionsNeedMoreSpace");
    $(".p-tag-too-wide-grow-along").css("max-width", "");
    var required_width = 0;
    $(".group table").each(function (_, table) {
      var $table = $(table),
        w = $table.width(),
        is_too_wide;
      $table.removeClass("bb-p-tag-too-wide");
      is_too_wide = w > $table.parent().width();
      if (is_too_wide) {
        required_width = Math.max(required_width, w);
        bb.Mode.set("questionsNeedMoreSpace");
      }
      if (is_too_wide) {
        //        $('.p-tag-too-wide-grow-along').css('max-width', required_width);
      } else {
      }
      $table.toggleClass("bb-p-tag-too-wide", is_too_wide);
    });
  }

  $(function () {
    $(win).on("resizeEnd", function () {
      if (bb.Mode.get("hasModel")) tagTables();
    });

    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.groups) tagTables();
    });
  });
})(jQuery, window, document);
