import { bb } from "$json";
/* authorize-internal-links:
 *
 * Authorize faulty 'internal links'
 *
 * Links to other models (of the form of e.g. open?modelname=xxx) and
 * to XSLT-based preview documents (of the form report?template=xxx)
 * are misinterpreted by the JSON engine as links to generated
 * documents. Therefore said engine appends a querystring with
 * identifier (guid). Thus the url ends up with two parameters in
 * fact. This plugin discards said identifier after using it to fill
 * the uniqueid parameter instead. In the case of XSLT-based preview
 * documents, also dbname and sessionid are interpreted and used.
 *
 * Caveats (< 3.5 version of server): when user follows a link to a
 * model and then logs out (in the new window), the 'parent model' is
 * logged out of as well, i.e. when user returns to the model, it is
 * dead. This happens easily when calling into a standard
 * JavaScript-driven web environment. It is therefore advised to use
 * this only to link to HTML-driven environments. Needless to say,
 * links to XSLT-based preview documents do not suffer from this
 * constraint.
 *
 * Links inside information sources of the strict form of
 * xxx.html?modelname=xxx also get authorized with a uniqueid.
 *
 * @todo: Handle extra params in information sources (e.g.
 * xxx.html?modelname=xxx&xtra:id=08724) -- these type of urls are now
 * ignored, i.e. left alone.
 *
 * Author: Niels Giesen
 * Copyright 2014, 2015 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var corrigendo = /((.*)\?(.*))\?guid=(.+):(.+):(.+)$/;

  function fixIt(_, control) {
    if (control.controltype === "linklabel") {
      var m;
      if ((m = control.url.match(corrigendo))) {
        // Strip the guid part
        control.url = m[1];
        // Explicit credentials may have been provided in the link
        // itself - in which case we do not want the uniqueid as well.
        if (!($.parseQuery(m[3]).username && $.parseQuery(m[3]).password)) {
          var params = { uniqueid: m[4] };
          if (m[2] === "report") {
            // Live XSLT report
            params.dbname = m[5];
            params.sessionid = m[6];
          }
          control.url += "&" + $.param(params);
          control.isreport = false;
        }
      }
    } else if (control.controltype === "grid") {
      $.each(control.columns, function (i, column) {
        if (column.controltype === "linklabel") {
          $.each(control.value, function (ii, row) {
            row[i].controltype = "linklabel";
            fixIt(undefined, row[i]);
            delete row[i].controltype;
          });
        }
      });
    }
  }

  $(function () {
    $(document).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        $.each(data.groups, function (i, group) {
          $.each(group.controls, fixIt);
        });
      }
      if (data && data.informationsources) {
        $.each(data.informationsources, function (_, source) {
          var m;
          if (
            source.isurl &&
            (m = source.content.match(/(.*)\?modelname=(.+)$/))
          ) {
            var params = { uniqueid: data.uniqueid };
            source.content = m[0] + "&" + $.param(params);
          }
        });
      }
    });
  });

  bb.authorizeInternalLinks = function (data) {
    if (data && data.groups) {
      $.each(data.groups, function (i, group) {
        $.each(group.controls, fixIt);
      });
    }
  };
})(jQuery, window, document);
