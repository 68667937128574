/* global $ */
/* auto-next:
 *
 * Automatically go next on selection of radiogroup option, when that
 * radiogroup is the only editable question in the node.
 *
 * This behaviour used to be provided by our core module, but there
 * were many users who did not like it and disabled it by using the
 * now removed plugin `no-auto-next'.
 *
 * The behaviour is now reversed: automatically going next is not
 * supported by default, and if you do want it, use this plugin.
 *
 * Author: Niels Giesen
 * Copyright 2013, 2015, 2020 Berkeley Bridge
 *
 * Dependcy: questionlabelgroup-ng (or similar grouper?)
 *
 */

import { bb } from "$json";
import { isQuestion, isVisible, isReadOnly } from "$json/lib/control-helpers";
import {
  allPass,
  both,
  complement,
  compose,
  filter,
  length,
  map,
  pathOr,
  propEq,
  when
} from "$json/lib/functional";

let evenWhenDynamic = pathOr(false, [
  "arbitrary",
  "auto-next-when-only-radio",
  "evenWhenDynamic"
]);

const changeHandler = ({ currentTarget }) => {
  const control = $.data(currentTarget, "control");

  if (!currentTarget.closest(".group.selected")) return;

  const controls = control._group.controls;

  if (hasMultipleQuestions(controls)) return;

  if (!bb.Mode.get("a-mouse-user")) return;

  bb.next();
};

const isInteractionable = allPass([
  isQuestion,
  isVisible,
  complement(isReadOnly)
]);

// there is more than one interactionable control?
const hasMultipleQuestions = compose(
  x => x > 1,
  length,
  filter(isInteractionable)
);

const radios = filter(both(isQuestion, propEq("controltype", "radio")));

const addChangeHandler = when(Boolean, widget =>
  widget.addEventListener("change", changeHandler)
);

const makeLastInputDoNext =
  /*
   * Bind click event on last logical switching input elements to
   * go to the next screen automatically
   */
  compose(
    map(control => {
      addChangeHandler(document.getElementById(control.id));
    }),
    radios
  );

$(document).on("bb:postHandleData", function (event, data) {
  if (
    typeof data !== "undefined" &&
    data &&
    data.groups &&
    data.groups.length
  ) {
    data.groups.forEach(function (group) {
      if (evenWhenDynamic(bb.conf) || !group.dynamic)
        makeLastInputDoNext(group.controls);
    });
  }
});
