/* previews-include-audit-trail:
 *
 * Prepend the audit trail to the list of documents.
 *
 * Translatable strings in this file:
 *
 * 'Audit Trail'
 *
 * Author: Niels Giesen
 * Copyright (C) 2015 by Berkeley Bridge
 */

import { bb, _ } from "$json";

(function ($) {
  var position = bb.propFinder(
    bb.conf,
    "arbitrary.previews-include-audit-trail"
  )("position", "before");

  var method = { before: "unshift", after: "push" }[position];

  if (method === undefined)
    throw (
      'arbitrary.previews-include-audit-trail.position "' +
      position +
      "' is invalid; should be one of 'before' or 'after'"
    );

  $(document).on("bb:preHandleData", function (event, data) {
    if (data && data.documents) {
      data.documents[method]({
        document: "report",
        name: _("Audit Trail"),
        href: function () {
          return (
            "report?" +
            "dbname=" +
            data.dbname +
            "&sessionid=" +
            data.sessionid +
            "&uniqueid=" +
            data.uniqueid +
            "&template=" +
            this.document
          );
        }
      });
    }
  });
})(jQuery);
