/* none-of-the-above:
 *
 * Bind the
 * last item of a checklist with class bbm-none-of-the-above to
 * uncheck all the others
 *
 * Usage:
 *
 * For multichecklists: give the multichecklist the font style
 * none-of-the-above.
 *
 * For groups of single checkboxes: give the last checkbox in the row
 * (the 'none of the above' option) the font style none-of-the-above.
 * NOTE: This requires plugin questionlabelgroup.js to be included.
 *
 * Author: Niels Giesen
 *
 * Copyright 2013 Berkeley Bridge
 *
 */
(function ($) {
  $(function () {
    $(document).on(
      "click",
      ".bbm-none-of-the-above li:last-child input",
      function (ev) {
        if ($(this).prop("checked"))
          $(this)
            .closest("li")
            .siblings()
            .removeClass("checked")
            .find("input")
            .prop("checked", false);
      }
    );

    $(document).on(
      "click",
      ".bbm-none-of-the-above li:not(last-child) input",
      function (ev) {
        if ($(this).prop("checked"))
          $(this)
            .closest("li")
            .siblings(":last-child")
            .addClass("checked")
            .find("input")
            .prop("checked", false);
      }
    );
  });

  $(function () {
    $(document).on("click", "input[type=checkbox]", function (ev) {
      if ($(this).is(":checked")) {
        var nexts, prevs;
        nexts = $(this)
          .parent(".bb-questionlabelgroup")
          .nextUntil(":not(:has(input[type=checkbox]))");
        if (nexts.length > 0)
          nexts
            .filter(":last")
            .find("input.bbm-none-of-the-above")
            .prop("checked", false);
        else if ($(this).is(".bbm-none-of-the-above")) {
          prevs = $(this)
            .parent(".bb-questionlabelgroup")
            .prevUntil(":not(:has(input[type=checkbox]))");
          prevs.find("input").prop("checked", false);
        }
      }
    });
  });
})(jQuery);
