export class BooleanBucket {
  constructor() {
    this.booleans = [];
  }
  /**
   * @param {String} key Key to get
   */
  get(key) {
    return this.booleans[key];
  }
  /**
   * @param {String} key Key to set
   */
  set(key) {
    return this.toggle(key, true);
  }
  /**
   * @param {String} key Key to unset
   */
  unset(key) {
    return this.toggle(key, false);
  }
  /**
   * @param {String} key Key to toggle
   * @param {Boolean} [bool] New value
   */
  toggle(key, bool) {
    bool = typeof bool === "boolean" ? bool : !this.get(key);
    if (bool !== this.get(key)) {
      this.booleans[key] = bool;
      return [true, bool];
    } else {
      return [false, bool];
    }
  }
}
