/* faux-labels:
 *
 * Deprecation notice 2021:
 *
 * CSS would probably be a better fit, think it's just for
 * looks, and may not play well with dynamic values. Do *not* use this
 * plugin in new layers
 *
 * Turn **readonly** "faux-label" datatype fields into faux labels
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
import { registerWidget } from "$json/lib/form-widgets";

console && console.warn && console.warn("Plugin faux-labels is deprecated");

(function ($, win, doc) {
  var datatype = "faux-label",
    widget = {
      name: "faux-label",
      tagName: "div",
      attribs: () => ({}),
      render: function (control, $widget, group) {
        $widget.text(control.value);
      }
    };

  registerWidget(widget);

  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        $.each(data.groups, function (_, group) {
          $.each(group.controls, function (_, control) {
            if (control.readonly && control.datatype === datatype) {
              control.controltype = "faux-label";
            }
          });
        });
      }
    });
  });
})(jQuery, window, document);
