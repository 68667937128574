/* broadcast-fields:
 *
 * Broadcast field properties, for other windows/tabs to act upon
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 *
 */

import { bb } from "$json";
(function ($, win, doc) {
  if ("addEventListener" in win) {
    // Custom finder for our plugin values.
    var findSetting = bb.propFinder(bb.conf, "arbitrary.broadcast-fields");

    // Trust ourselves plus a configurable set
    var trustedOrigins = []
      .concat(window.location.origin, findSetting("trustedOrigins"))
      .filter(Boolean);

    // Return a function that tells we can trust an origin
    function trust(trusted) {
      return function (origin) {
        return trusted.indexOf(origin) > -1;
      };
    }

    /**
     * Find the control element for label
     *
     * @param {Node} label: A label element labelling an input.
     */
    function getControl(label) {
      return (
        label.control || document.getElementById($(label).data("control").isfor)
      );
    }

    /**
     * Collect all labels of current node that have associated
     * controls
     *
     */
    function collectLabels() {
      return doc.querySelectorAll("#bb-q .selected .bb-label");
    }

    /**
     * Get text value for control
     */
    function getTextValue(control) {
      var controldata = $(control).data("control"),
        kind = controldata.controltype,
        value,
        option;
      switch (kind) {
        case "radio":
        case "checkmultilist":
        case "customlist":
        case "combobox":
        case "listbox":
        case "multilist":
          option = control.querySelectorAll(":checked");
          var values = [];
          if (option.length) {
            for (var i = option.length - 1; i >= 0; i--) {
              values.push(option[i].value);
            }
            value = controldata.value
              .filter(function (opt) {
                return ~values.indexOf(opt.value);
              })
              .map(function (item) {
                return item.option;
              });
          }
          break;
        case "checkbox":
          value = control.checked;
          break;
        case "listlabel":
          value = controldata.value;
          break;
        case "linklabel":
        case "grid":
          // Ignore grids
          value = undefined;
          break;
        default:
          value = control.value;
      }
      return value;
    }

    function ignoreEvent(fun) {
      return function (_) {
        fun();
      };
    }

    /**
     * Broadcast labels and values to other windows
     *
     * @param {page} Window Optional window object. We will be
     * broadcasting there.
     */

    function broadcast(page) {
      if (page === undefined) page = window.opener || window.parent;
      var labels = collectLabels(),
        ob = [],
        control,
        kind,
        textValue,
        failureSettingItem = false;
      for (var i = 0; i < labels.length; i++) {
        control = getControl(labels[i]);
        if (!control) continue;
        ob.push({
          label: control.getAttribute("data-label") || labels[i].textContent,
          value: getTextValue(control)
        });
      }
      page.postMessage(
        JSON.stringify({
          key: "bb-p-broadcast-fields",
          value: ob
        }),
        page.origin || page.location.origin
      );
    }

    $(function () {
      /**
       * Store values
       */
      $(doc).on("click", ".bb-p-broadcast-fields_all", ignoreEvent(broadcast));
    });

    /**
     * Export broadcast functions
     */
    bb.Plugins = $.extend({}, bb.Plugins, {
      "broadcast-fields": {
        collectLabels: collectLabels,
        cast: broadcast,
        getControl: getControl,
        trust: trust,
        trustedOrigins: trustedOrigins
      }
    });
  }
})(jQuery, window, document);
