/* model-filter:
 *
 * Put models that are yours in their own div.
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    var $selector = $("#bb-p-models-yours"),
      init_contents = $selector.html();

    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.models) {
        $selector.empty();
        $selector.append(init_contents);
        $(".bb-model-yours-true").appendTo($selector);
      }
    });
  });
})(jQuery, window, document);
