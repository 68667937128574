/* model-filter:
 *
 * Filter models by name
 *
 * Author: Niels Giesen, Annegreet Olijve
 * Copyright 2013, 2014 Berkeley Bridge
 *
 */

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}
(function ($, doc) {
  var $filter, $taxonomy;
  var taxonomy = [],
    otags = [],
    models = [];

  $(function () {
    $filter = $("#bb-p-model-filter");

    $filter.on("keydown", function (ev) {
      if (ev.keyCode === 13) {
        var $filtered = $(".bb-model:visible");
        if ($filtered.length === 1)
          $filtered.find(".bb-newcase").trigger("click");
      }
    });

    $(doc).on("change", "#bb-p-model-filter-taxonomy select", filter);
    $(doc).on("keyup", "#bb-p-model-filter", filter);
    $(doc).on("click touchstart", "#bb-p-model-filter-clear", clear);

    $(doc).on("bb:postHandleData", function (event, data) {
      if (typeof data != "undefined" && data.taxonomy) {
        taxonomy = data.taxonomy;
      }
      if (typeof data != "undefined" && data.models) {
        models = data.models;
      }

      if (!$taxonomy) {
        var html = "";
        $taxonomy = $("#bb-p-model-filter-taxonomy");
        $.each(taxonomy, function (_, category) {
          html += "<div><label>" + category.name + "</label> ";
          html += "<select><option value=''>...</option>";
          category.tags = sortByKey(category.tags, "name");
          $.each(category.tags, function (_, tag) {
            html += "<option value='" + tag.id + "'>" + tag.name + "</option>";
          });
          html += "</select></div>";
        });
        $taxonomy.html(html);
      }

      filter();
    });
  });

  function filter() {
    $("#bb-p-model-filter-nomatches").hide();
    var val = $.trim($filter.val());
    var hasmatch = false;
    var anyactivefilter = false;
    var tags = [];
    if ($taxonomy) {
      tags = $.grep(
        $.map($taxonomy.find("select"), function (category) {
          return category.value;
        }),
        Boolean
      );
    }
    var tagcount = tags.length;

    if (tagcount > 0 || val.length >= 3) {
      anyactivefilter = true;
      var selectedmodels = {};
      $.each(models, function (_, model) {
        var match = true;
        for (var i = 0; i < tagcount; i++) {
          if ($.inArray(tags[i], model.tags) == -1) {
            match = false;
            break;
          }
        }
        if (match) selectedmodels[model.dbname] = true;
      });

      var re = new RegExp(val, "i");
      $(".bb-model").each(function () {
        var $this = $(this);
        // Find the description in the child or -- when absent -- as
        // the text content itself
        var text = $this.find(".bb-model-name").text() || $this.text();
        var dbname = $this.attr("data-bb:dbname");
        var textmatch = val.length < 3 || re.test(text);
        var ismatch = !!(selectedmodels[dbname] && textmatch);
        hasmatch = hasmatch || ismatch;
        $this.toggle(ismatch);
      });
    }
    $filter.toggleClass("bb-p-model-filter-has-no-match", !hasmatch);
    if (!anyactivefilter) {
      $(".bb-model").show();
    } else if (!hasmatch) {
      $("#bb-p-model-filter-nomatches").show();
    }
  }

  function clear() {
    $filter.val("").trigger("keyup").trigger("focus");
  }
})(jQuery, document);
