/* broadcast-fields--receiver:
 *
 * Stuff for the receiving side of broadcasted info
 *
 * Author: Niels Giesen
 * Copyright 2016, 2019, Berkeley Bridge
 *
 */
import { bb } from "$json";

(function ($) {
  var _popup = { closed: true, origin: null };

  var broadcast = bb.Plugins["broadcast-fields"];
  var collectLabels = broadcast.collectLabels;
  var getControl = broadcast.getControl;
  var findSetting = bb.propFinder(bb.conf, "arbitrary.broadcast-fields");
  var windowName = findSetting("openee", "window");
  var windowOpenProps = findSetting(
    "windowOpenProps",
    "width=500,height=650,left=100,top=200,resizable,scrollbars=yes,resizable"
  );

  /**
   * Event handler.
   *
   * Open link in other, special window, regardless of
   * whether it has <tt>target="_blank"</tt> set.
   *
   * @param {Event} ev The event invoking the function.
   */
  function openContactsLink(ev) {
    _popup = window.open(this.href, windowName, windowOpenProps);
    if (windowName !== "window") {
      // For the sake of forcing tabs to react.
      $('[name="' + windowName + '"]').attr(
        "data-hooked",
        String(Math.random())
      );
      /*
        The src field is ignored when calling window.open() with an iFrame
        as reference name, but we need to change it anyway so that tabs.js
        realizes it is not actually displaying what the src attr suggests.
      */
      $('[name="' + windowName + '"]').attr("src", this.href);
    }

    ev.preventDefault();
    ev.stopImmediatePropagation();
    return false;
  }

  /**
   * Attach openExternalLink Event to links containing `contacts.html`
   */
  $(document).on("click", 'a[href*="contacts.html"]', openContactsLink);

  /**
   * React to message
   */
  if (window.addEventListener) {
    window.addEventListener("message", receiveMessage, false);
  } else if (window.attachEvent) {
    window.attachEvent("onmessage", receiveMessage);
  }

  function receiveMessage(evt) {
    if (!broadcast.trust(broadcast.trustedOrigins)(evt.origin)) return;
    try {
      var data = JSON.parse(evt.data);
    } catch (e) {
      return;
    }
    if (data.key === "bb-p-broadcast-fields") {
      if (data.value) adopt(data.value);
      if (evt.source === _popup) _popup.close();
    }
    if (data.ready) {
      broadcast.cast(_popup);
    }
  }

  /**
   * Return item matching CONTROL or LABEL from VALUES, removing it
   * when found
   *
   * @param {Node} control: An input element
   * @param {String} label: The (initial) label text
   * @param {Array} values: Array of { label : String, value : String}
   */
  function popFirstMatch(control, label, values) {
    for (var i = 0; i < values.length; i++) {
      if (values[i].label === (control.getAttribute("data-label") || label)) {
        return values.splice(i, 1)[0];
      }
    }
    return undefined;
  }
  /**
   * Set input values to the input values received
   *
   * @param {Array} values: Array of { label : String, value : String}
   */
  function adopt(values) {
    var labels = collectLabels();
    for (var i = 0; i < labels.length; i++) {
      var label = labels[i],
        control = getControl(label);
      if (!control) continue;
      var $control = $(control),
        match = popFirstMatch(control, label.textContent, values);
      // @todo: create interfaces for our own controls, outside of
      // this plugin.
      if (match) {
        setTextValue(control, match.value);
      }
    }
  }

  /**
   * Set value on control by text value
   */
  function setTextValue(control, value) {
    var $control = $(control),
      controldata = $control.data("control"),
      options,
      select;
    switch (controldata.controltype) {
      case "radio":
      case "checkmultilist":
      case "customlist":
      case "combobox":
      case "listbox":
      case "multilist":
        if (!(value instanceof Array)) value = [value]; // Dest is an array, source is not.
        select = controldata.value
          .filter(function (opt) {
            return ~value.indexOf(opt.option);
          })
          .map(function (opt) {
            return opt.value;
          });
        options = control.querySelectorAll("input, option");
        for (var len = options.length, i = 0; i < len; i++) {
          var checked = ~select.indexOf(options[i].value);
          if (control instanceof HTMLSelectElement)
            options[i].selected = checked;
          else {
            options[i].checked = checked;
            $(options[i]).trigger("change");
          }
        }
        break;
      case "checkbox":
        control.checked = value;
        break;
      case "linklabel":
      case "listlabel":
      case "grid":
        return;
      // Ignore links, lists and grids (unsettable)
      default:
        if (value instanceof Array) value = value[0]; // Source is an array, dest is not.
        if ($control.hasClass("hasDatepicker"))
          $control.datepicker("setDate", value);
        else control.value = value;
    }
    $control.trigger("change");
  }
  /**
   * Close the _popup window when closing main
   */
  $(window).on("beforeunload", function (ev) {
    !_popup.closed && _popup.close();
  });
})(jQuery);
