import parse from "./baby-lisp.js";
import * as L from "./functional.js";

/**
 * @typedef {Object} Bucket
 * @property {method} get Method to get key
 */

/**
 * Test string against a bucket's contents
 * @function
 * @param {Bucket} bucket with a get(key) method returning a Boolean
 * or undefined.
 * @param {String} string Lispy string to test against the bucket
 */
export const stringSaysDo = (bucket, string) => {
  const ast = parse(`(${string})`);
  return astSaysDo(bucket)(ast);
};

/**
 * Test ast against bucket's contents
 * @function
 * @param {Bucket} bucket with a get(key) method returning a Boolean
 * or undefined.
 * @param {Object} AST Lispy AST (nested array of strings) to test against the bucket
 */
const astSaysDo = bucket => ast => {
  if (ast instanceof Array) {
    if (ast[0] === "and") {
      return L.compose(L.all(astSaysDo(bucket)), L.tail)(ast);
    }
    if (ast[0] === "or") {
      return L.compose(L.any(astSaysDo(bucket)), L.tail)(ast);
    }
    if (ast[0] === "not") {
      return L.compose(L.not, L.any(astSaysDo(bucket)), L.tail)(ast);
    }
    return L.compose(L.any(astSaysDo(bucket)))(ast);
  } else {
    return bucket.get(ast);
  }
};
