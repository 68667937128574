/* asterisk:
 *
 * Add asterisk to labels for required fields
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  function appendAsterisk(child) {
    if (child.nodeType === 3 /* Node.TEXT_NODE */) {
      var val = child.nodeValue,
        space = "",
        words = val.split(" "),
        lastword = words.pop();
      // json.js adds a space for visual purposes
      if (lastword === "") {
        space = " ";
        lastword = words.pop();
      }
      child.nodeValue = words.join(" ");
      child.parentNode.insertAdjacentHTML(
        "beforeEnd",
        ' <span class="bb-p-asterisk-stick">' +
          lastword +
          `<sup aria-hidden="true">&nbsp;*</sup>` +
          "</span>" +
          space
      );
    } else if (child.nodeType === 1 /* Node.ELEMENT_NODE */) {
      if (child.lastChild) appendAsterisk(child.lastChild);
    }
  }

  $(doc).on("bb:updated", function (event, $widget, control, updates) {
    if (updates.indexOf("isForNotNull") > -1) {
      if (control.isForNotNull) {
        appendAsterisk($widget.get(0).lastChild);
      } else {
        const $stick = $widget.find(".bb-p-asterisk-stick");
        if (!$stick.get(0)) return;
        $stick.find("sup").remove();
        const textnode = $stick.get(0).childNodes[0];
        if (textnode) $(textnode).unwrap();
      }
    }
  });
})(jQuery, window, document);
