let _token;

export const setToken = token => {
  _token = token;
};

export async function digestMessage(message) {
  if (!("crypto" in window)) return null;
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hashHex;
}

export const tokenChannel =
  "BroadcastChannel" in window ? new BroadcastChannel("token") : "";

export const token = {
  addEventListener: (...args) =>
    tokenChannel && tokenChannel.addEventListener(...args),
  removeEventListener: (...args) =>
    tokenChannel && tokenChannel.removeEventListener(...args),
  getHash: () => digestMessage(_token),
  setToken,
  postMessage: message =>
    token.getHash().then(
      tokenHash =>
        // digestMessage will return null on missing crypto on older
        // browsers
        tokenHash &&
        tokenChannel &&
        tokenChannel.postMessage({ tokenHash, ...message })
    )
};
