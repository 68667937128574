/* all-or-nothing:
 *
 * Select all or nothin' in checklists
 *
 * Author: Niels Giesen
 * Copyright 2012,2013,2019 Berkeley Bridge
 *
 */
/* global $ */
import { bb } from "$json";

import { Mode } from "$json/lib/mode.js";

import { getControl } from "$json/lib/control";

import { _ } from "$json/lib/gettext";

import {
  curry,
  complement,
  compose,
  ifElse,
  map,
  path,
  tap
} from "$json/lib/functional";

import { isReadOnly } from "$json/lib/control-helpers";

let sub = "p-all-or-nothing";

_.addTranslations({
  [sub]: {
    en: {
      select: "select",
      all: "all",
      or: "or",
      nothing: "nothing"
    },
    nl: {
      select: "selecteer",
      all: "alles",
      or: "of",
      nothing: "niets"
    },
    fr: {
      select: "choisissez",
      all: "tout",
      or: "ou",
      nothing: "rien"
    },
    de: {
      select: "wahlen Sie",
      all: "alles",
      or: "oder",
      nothing: "nichts"
    },
    ru: {
      select: "выбирайте",
      all: "всё",
      or: "или",
      nothing: "ничего"
    }
  }
});

const CONTAINERCLASS = "bb-p-all-or-nothing-container";
const TOGGLERCLASS = "bb-p-all-or-nothing-toggler";

const togglerTemplate = _.taggedWithin(sub)`<label class="bb-p-all-or-nothing">
   <input class="${TOGGLERCLASS}" type="checkbox" />
     <span class="bb-p-all-or-nothing-labeltext">${"select"} <span class="bb-p-all-or-nothing-all">${"all"}</span>
      <span aria-hidden="true"> ${"or"} </span><span aria-hidden="true" class="bb-p-all-or-nothing-nothing">${"nothing"}</span>
    </span>
   </label>`;

const nearestBy = curry((fn, node) => {
  try {
    while (node && !fn(node)) {
      node = node.parentNode;
    }
  } catch (e) {
    node = null;
  }
  return node;
});

const getGroup = nearestBy(node =>
  node.classList.contains("bb-questionlabelgroup")
);

const isGrid = node => node.dataset["type"] === "grid" && node.tHead;

const isInGrid = elt => nearestBy(isGrid, elt);

const getCheckboxSelector = elt => {
  if (isInGrid(elt)) {
    const th = nearestBy(node => node.tagName === "TH", elt);
    const col = [...th.parentElement.children].indexOf(th);
    return `[name$=".${col}"]`;
  }
  return 'input[type="checkbox"]';
};

const ignorable = elt =>
  elt.closest("tr, .bb-option").matches(".p-option-filter__hidden");

const setStateOnChildren = elt => {
  elt.parentNode.classList.add("bb-p-all-or-nothing-on");
  const checked = elt.checked;
  const group = getGroup(elt);
  for (let cb of group.querySelectorAll(getCheckboxSelector(elt))) {
    if (cb !== elt) {
      if (ignorable(cb)) continue;
      cb.checked = checked;
    }
  }
  if (Mode.get("hasDynamicInterfaces")) {
    bb.update();
  }
};

const stateReducer = (acc, cur) =>
  acc === "mixed" || acc === cur ? acc : acc === undefined ? cur : "mixed";

const stateFromChildren = elt => {
  const group = nearestBy(
    node => node.classList.contains("bb-questionlabelgroup"),
    elt
  );

  return [...group.querySelectorAll(getCheckboxSelector(elt))]
    .filter(cb => cb !== elt)
    .filter(complement(ignorable))
    .map(cb => cb.checked)
    .reduce(stateReducer, undefined);
};

const setStateFromChildren = elt => {
  const state = stateFromChildren(elt);

  elt.indeterminate = state === "mixed";

  if (state === "mixed") {
    elt.parentNode.classList.remove("bb-p-all-or-nothing-on");
  } else {
    elt.checked = state;
    elt.parentNode.classList.add("bb-p-all-or-nothing-on");
  }
};

const megaEvent = ev => {
  if (ev.target.classList.contains(TOGGLERCLASS)) {
    setStateOnChildren(ev.target);
  } else {
    const widget = nearestBy(
      node => ["grid", "checkmultilist"].includes(node.dataset["type"]),
      ev.target
    );
    if (isGrid(widget)) {
      const col = ev.target.name.split(".").pop();
      const group = getGroup(ev.target);
      setStateFromChildren(
        group.querySelector(`th:nth-child(${Number(col) + 1}) .${TOGGLERCLASS}`)
      );
    } else {
      const group = getGroup(ev.target);
      setStateFromChildren(group.querySelector(`.${TOGGLERCLASS}`));
    }
  }
};

$(document).on("click", `.${CONTAINERCLASS} input[type="checkbox"]`, megaEvent);

const metadataSaysNo = compose(
  val => val === "off",
  path(["metadata", "p-all-or-nothing"]),
  getControl
);

const toggledOnes = new WeakSet();

const createToggleElement = () => {
  const frag = document.createElement("div");
  frag.innerHTML = togglerTemplate;
  return frag.children[0];
};

const flipDisabled = control => toggler => {
  toggler.querySelector(`.${TOGGLERCLASS}`).disabled = isReadOnly(control);
};

const addTogglerToCell = cell => {
  $(cell).showdown();
  cell.classList.remove("bb-md-able");
  const toggler = createToggleElement();
  const origcontents = cell.childNodes;

  toggler.querySelector("input").setAttribute("aria-describedby", cell.id);

  const celldiv = document.createElement("div");
  celldiv.classList.add("p-all-or-nothing-toggler-cell-contents");

  const origdiv = document.createElement("div");
  origdiv.classList.add("p-all-or-nothing-toggler-cell-orig-contents");
  origdiv.append(...origcontents);
  origdiv.id = cell.id;
  cell.removeAttribute("id");

  celldiv.append(toggler, origdiv);

  cell.insertAdjacentElement("afterbegin", celldiv);
  cell.classList.add("p-all-or-nothing-toggler-cell");

  return toggler;
};

const addGridTogglers = widget =>
  map(addTogglerToCell)([
    ...widget.querySelectorAll(
      `[data-rowtype="checkbox"][data-rowreadonly="false"]:not(.p-all-or-nothing-toggler-cell)`
    )
  ]);

const listenForGridUpdates = widget => {
  widget.addEventListener("bb:updatedControl", ({ detail }) => {
    if (detail.Updates.columns) {
      addGridTogglers(widget);
    }
  });
  widget.addEventListener("bb-p:option-filtered", () => {
    widget.querySelectorAll(`.${TOGGLERCLASS}`).forEach(setStateFromChildren);
  });
};

const addTogglers = ifElse(
  isGrid,
  widget => {
    listenForGridUpdates(widget);
    return addGridTogglers(widget);
  },
  widget => {
    const toggler = createToggleElement();
    widget.insertAdjacentElement("beforebegin", toggler);
    return [toggler];
  }
);

const togglify = widget => {
  if (metadataSaysNo(widget)) return;
  if (toggledOnes.has(widget)) return;
  const control = $.data(widget, "control");
  const group = getGroup(widget);
  group.classList.add(CONTAINERCLASS);

  compose(
    map(
      compose(
        flipDisabled(control),
        tap(
          compose(setStateFromChildren, elt =>
            elt.querySelector(`.${TOGGLERCLASS}`)
          )
        )
      )
    ),
    addTogglers
  )(widget);

  toggledOnes.add(widget);
};

$(function () {
  const DEFAULT_SELECTOR = `.checklist:has(li:nth-child(2)), [data-type="grid"]:has(tr:nth-child(2)):has(input[type="checkbox"])`;
  const SELECTOR = bb.propFinder(bb.conf)(
    "arbitrary.all-or-nothing.selector",
    DEFAULT_SELECTOR
  );

  $(document).on("bb:postHandleData", function (event, data, _status, _req) {
    if (data && data.groups) {
      $(SELECTOR, ".group").each((i, item) => togglify(item));
    }
  });

  document.addEventListener("bb:updatedControl", function (event) {
    if ($(event.target).is(SELECTOR)) {
      togglify(event.target);
    }
  });
});
