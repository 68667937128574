/* non-indenting-jumplist:
 *
 * Throw away indentation level information of the jumplist, so that a
 * flat jumplist will be created.
 *
 * This can be useful for horizontally layed-out jumplists.
 *
 * Copyright (C) 2010, 2011, 2015 by Berkeley Bridge
 *
 */

(function ($) {
  $(document).on("bb:preHandleData", function (event, data) {
    if (data.jumplist) {
      $.each(data.jumplist, function (group) {
        data.jumplist[group].level = 0;
        data.jumplist[group].screentitle =
          data.jumplist[group].screentitle.trim();
      });
    }
  });
})(jQuery);
