(function ($) {
  $(document).on("bb:errorOn", ".bb-input-wrap", adderrorclass);
  $(document).on("bb:errorOff", ".bb-input-wrap", rmerrorclass);

  function adderrorclass(ev) {
    $(ev.currentTarget).addClass("bb-input-wrap--error");
  }

  function rmerrorclass(ev) {
    $(ev.currentTarget).removeClass("bb-input-wrap--error");
  }

  $(document).on("click", ".selected .bb-input-wrap", focus);

  function notAnInput(node) {
    return node.form === undefined; // With form inputs, this will
    // be either the form or null
  }
  function focus(ev) {
    if (notAnInput(ev.target)) {
      ev.currentTarget.querySelector("input, select, textarea").focus();
    }
  }
})(jQuery);
